// import Button from "../common/Button";

import Background from "../../assets/images/socials.jpg";
import BasePageHeader from "../common/BasePageHeader";
import HeaderPanel from "../common/HeaderPanel";
// import {Link} from "react-router-dom";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faArrowDown} from "@fortawesome/free-solid-svg-icons";

const SocialsHeader = (props) => {
    return (
        <BasePageHeader background={Background} sectionName={"Socials Header"} backgroundClassName={`object-center`}>
            <HeaderPanel className={`
                    md:w-[30rem] h-auto md:top-28 md:left-4 md:bottom-auto
                `}>
                <h1 className="text-lg text-white font-semibold">
                    We bond and socialise!
                </h1>
                <blockquote>
                    <p className="text-md md:text-lg text-white font-normal">
                        Sydney Tsunami Dragon Boat Club welcomes new paddlers of all ages
                        and fitness levels! It&apos;s a great way to meet a new bunch of
                        friendly people in Sydney and to keep fit at the same time.
                    </p>
                </blockquote>
                {/*<Link*/}
                {/*    // activeClass="Home"*/}
                {/*    to="paddlers"*/}
                {/*    smooth={true}*/}
                {/*    offset={-100}*/}
                {/*    duration={500}*/}
                {/*>*/}
                {/*    <div className="cursor-pointer flex items-center shadow-md">*/}
                {/*        <div className="bg-white rounded-l-md text-sm text-club-red border border-club-red p-2">*/}
                {/*            Meet our paddlers*/}
                {/*        </div>*/}
                {/*        <FontAwesomeIcon*/}
                {/*            className="rounded-r-md w-8 bg-club-red text-white p-2 border border-club-red"*/}
                {/*            icon={faArrowDown}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Link>*/}
            </HeaderPanel>
        </BasePageHeader>
    );
};

export default SocialsHeader;
