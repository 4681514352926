import Logo from "./Logo";

import {useEffect, useState, useRef} from "react";
import MenuBarLink from "./MenuBarLink";
// import LinkButton from "./LinkButton";

import {Transition} from "@headlessui/react";
import {Link} from "react-router-dom";
import HamburgerIcon from "../utils/HamburgerIcon";
import TryPaddling from "../widgets/TryPaddling";

const MenuBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);

    const scrollHandler = () => {
        const offset = window.scrollY;
        setSticky(offset > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
    });

    return (
        <nav className="shadow-sm fixed w-full z-50">
            <div className="w-full">
                <TryPaddling/>
                <div
                    className={`
                        flex items-center h-20 w-full transition ease-in-out duration-300
                        ${isSticky ? "bg-stone-900/90" : "bg-stone-900/30"}
                    `}>
                    <div className={`flex items-center px-2 md:px-8 text-white justify-between w-full `}>
                        <Link to={"/"}>
                            <div className="flex justify-center items-center flex-shrink-0 cursor-pointer">
                                <div className="relative top-4 z-20">
                                    <Logo/>
                                </div>

                                <div className="flex flex-col px-1">
                                    <h1 className="font-bold text-2xl md:text-3xl">
                                        Sydney <span>Tsunami</span>
                                    </h1>
                                    <p className="text-xs">Dragon Boat Club</p>
                                </div>
                            </div>
                        </Link>

                        <div className="hidden md:block">
                            <div className="mr-4 flex items-baseline space-x-4">
                                <MenuBarLink address={"/"} label={"Home"}/>
                                <MenuBarLink address={"/about"} label={"About"}/>
                                <MenuBarLink address={"/faq"} label={"FAQ"}/>
                                <MenuBarLink address={"/contactus?type=general"} label={"Contact us"}/>
                            </div>
                        </div>

                    </div>


                    <div className="mr-10 flex md:hidden relative">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className={`
                                inline-flex items-center justify-center p-2 text-white 
                                focus:outline-none focus:ring-white fixed top-5 right-3`}
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            <HamburgerIcon className={`block h-6 w-6`} flag={isOpen}/>
                        </button>
                    </div>
                </div>

            </div>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="md:hidden" id="mobile-menu">
                    <div ref={ref} className="bg-white px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <MenuBarLink address={"/"} label={"Home"} hamburger={true}/>
                        <MenuBarLink address={"/about"} label={"About us"} hamburger={true}/>
                        <MenuBarLink address={"/faq"} label={"Frequently Asked Questions"} hamburger={true}/>
                        <MenuBarLink address={"/contactus"} label={"Contact us"} hamburger={true}/>
                        <MenuBarLink address={"/newpaddler"} label={"Try Paddling for FREE"} hamburger={true}/>
                    </div>
                </div>
            </Transition>
        </nav>
    );
};

export default MenuBar;
