import {useState,
    // useEffect, useRef
} from "react";
import MainHeader from "../headers/MainHeader";
import CorporateHeader from "../headers/CorporateHeader";
import TrainingHeader from "../headers/TrainingHeader";
import SocialsHeader from "../headers/SocialsHeader";
import TeamBuildingHeader from "../headers/TeamBuildingHeader";

const Header = (props) => {
    const [headerIndex, setHeaderIndex] = useState(0);
    // const [stopRoll, setStopRoll] = useState(false);
    // const timer = useRef();

    // useEffect(() => {
    //   if (stopRoll) {
    //     clearInterval(timer.current);
    //   } else {
    //     timer.current = setInterval(() => {
    //       setHeaderIndex((headerIndex + 1) % 5);
    //     }, 10000);
    //   }
    //
    //   return () => {
    //     clearInterval(timer.current);
    //   };
    // }, [headerIndex, stopRoll]);

    return (
        <div className={`relative flex justify-center items-center`}>
            {headerIndex === 0 && <MainHeader
                // setStopRoll={setStopRoll}
            />}
            {headerIndex === 1 && <TrainingHeader/>}
            {headerIndex === 2 && <SocialsHeader/>}
            {headerIndex === 3 && <TeamBuildingHeader/>}
            {headerIndex === 4 && <CorporateHeader/>}

            <div className={`absolute bottom-16 flex self-center gap-8`}>
                {[0, 1, 2, 3, 4].map((index) => (
                    <div
                        key={index}
                        className={
                            ` bg-none border-2 border-white h-4 w-4 rounded-full
                 ${headerIndex === index && 'bg-white'}
               `}
                        onClick={() => setHeaderIndex(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Header;
