import {Link} from "react-router-dom";

const MenuBarLink = ({address, label, hamburger}) => {
    if (hamburger) {
        return (
            <div
                className="cursor-pointer hover:bg-red-600 text-black
                hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                <Link
                    to={address}
                    className="cursor-pointer hover:bg-red-600 text-black hover:text-white
                    block px-3 py-2 rounded-md text-base font-medium">
                    {label}
                </Link>
            </div>
        );
    }

    return (
        <div className="cursor-pointer hover:black px-2 py-2 rounded-md text-sm font-medium">
            <Link to={address}>{label}</Link>
        </div>
    );
}

export default MenuBarLink;
