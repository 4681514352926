import React from "react";

export default function DateInput({value, type, name, label, sending, changeHandler}) {
    return (
        <div className={`w-full flex flex-col border-b 
                ${value ? "border-black-100" : "border-red-400"}
                py-2
            `}>
            <label htmlFor={name}>
                {label}
            </label>
            <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 focus:outline-none"
                type={type}
                name={name}
                aria-label={label}
                onChange={changeHandler}
                disabled={sending}
                required
            />
        </div>
    )
}