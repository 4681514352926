import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoad = ({src, alt, className}) => {
    return (
        <LazyLoadImage src={src} alt={alt} effect="blur"
                       className={`
                            min-h-screen object-center object-cover pointer-events-none
                            ${className}
                       `}/>
    );
};

export default LazyLoad;
