// import {FacebookEmbed, InstagramEmbed} from "react-social-media-embed";

const Story = ({url, source, openStory}) => {
    const width = 350;

    return (
        <div className={`flex-initial cursor-pointer`} onClick={() => openStory(url)}>
            {source === "facebook" && (
                <div className="fb-post" data-href={url} data-width={width}></div>
            )}
            {source === "instagram" && (
                <iframe title={"instagram"} src={`${url}embed`}
                                               width={width}
                                               height="480"
                                               scrolling={"no"}></iframe>
            )}
        </div>
    )


    // return (
    //     <div className={`flex-initial`} onClick={() => openStory(url)}>
    //         {source === "facebook" && <FacebookEmbed
    //             url={url}
    //             embedPlaceholder={<div style={{
    //                 backgroundColor: 'lightsteelblue',
    //                 padding: '150px 0',
    //                 textAlign: 'center',
    //                 width: width
    //             }}>Custom Placeholder!</div>}
    //             width={width}
    //         />}
    //         {source === "instagram" && <InstagramEmbed
    //             url={url}
    //             width={width}
    //         />}
    //     </div>
    // );
};

export default Story;
