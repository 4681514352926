import {useState} from "react";
import Page from "./Page";
import Markdown from 'react-markdown'
// import Background from "../assets/images/about.webp";
import Background from "../assets/images/topview.jpg";
import BasePageHeader from "../components/common/BasePageHeader";
import HeaderPanel from "../components/common/HeaderPanel";
// import {Link} from "react-router-dom";

function About() {
    const [markdownSource,] = useState(defaultMarkdown);

    return (
        <Page
            title={"Who are we? Tsunami!"}
            description={`
                Sydney Tsunami is a club of Dragons who compete in mixed, open and women's.  Sydney Tsunami 
                Dragon Boat Club Inc. is a dragon boat club in Sydney, New South Wales, Australia. 
                Dragon boating is one of Australia's fastest growing sports, and we are always keen to have 
                new paddlers try us out.
            `}
        >
            <BasePageHeader sectionName={"About us"} background={Background}
                backgroundClassName={`brightness-150 md:brightness-100 md:object-left-top`}>
                <HeaderPanel className={`
                    md:left-8 top-36 max-h-[60rem] md:max-w-[40rem]
                    text-white bg-stone-900/80 md:bg-stone-900/80
                `}>
                    <h1 className={`font-bold text-2xl`}>About us</h1>
                    <div className={`overflow-auto space-y-8 text-white`}>
                        <Markdown className={`prose prose-stone text-white space-y-4`}>
                            {markdownSource}
                        </Markdown>
                    </div>
                </HeaderPanel>

            </BasePageHeader>
        </Page>);
}

export default About;

const defaultMarkdown = `
The origins of Sydney Tsunami begin in the early 90's when we
initially competed under the banner of our major sponsor Barclays,
and subsequently BZW and Barclays Global Investors.

The club was formed in October 1992 by three Barclay's staff
members, who had previously paddled with another Sydney dragon boat
club. After a shaky start to our first season the club was able to
record a 4th place in the corporate championship of the Sydney
Dragon Boat Festival at Darling Harbour.

By the end of the 1993/94 season, the team had won the Lord Mayors
Trophy for the corporate division at the Sydney Dragon Boat Festival
and was placed second at the Australian championships held in
Canberra.

In 1994/95 the Club entered two mixed teams in the Lord Mayors
Trophy at the Sydney Dragon Boat Festival and these teams finished
1st and 3rd. At the same festival, our women's crew won the
Australian women's championship.

Having won the corporate division title twice, in 1995/96 the club
moved up to compete in the highly competitive sports division.

One of the many highlights during this time was when, under the
guidance of the sports crew, the corporate team from Barclays Global
Investors won the Lord Mayor's Trophy in 1998 - to make us the only
club to have its name on the Lord Mayors Trophy three times.

Since then, the club has competed successfully locally, interstate
and internationally. In 1998/99 Sydney Tsunami was officially formed
with new uniforms and a new crew, combining experience with
enthusiasm. The club continues to compete in the mixed, open, and
women's divisions.

In the 2005/06 season, Sydney Tsunami was voted Club of the Year by
our peers, which reflects Tsunami's dedication to giving back to the
sport through volunteering.

In 2005, the crew travelled to The Philippines to compete, and
followed this with a successful trip to Penang in 2006.

Tsunami has fielded crews at State and National level since its
formation. The 2012 AusDBF Nationals in Melbourne was a landmark
event for the club. For the first time we made several finals across
Premier and Masters categories with our Masters and Premier Mens'
10s both bringing home Bronze medals, our first ever Nationals
bling!

In April 2012 members of the team again competed at the PDBF
sanctioned event held on the island of Boracay, in the Philippines,
to defend the Gold Medals they won in 2011. After the State Titles
in 2012 we were name Club of ther Year yet again.

In 2015 we went to Perth and qualified for the World Club Championships
in six different categories.  So in 2016 we competed in our first
‘Worlds’. And then we qualified again in 2017.   We are now rebuilding after
the COVID years and looking forward to introducing new paddlers to the world of
Dragonboats.

Tsunami aims to continue to compete at a high level, promote good
sportsmanship, aid in the development of the sport of dragon
boating, and follow to our motto - "Serious Fun".

BEWARE THE RED AND BLACK!
`
