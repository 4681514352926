import Background from "../../assets/images/main-min.jpg";
import HeaderPanel from "../common/HeaderPanel";
import BasePageHeader from "../common/BasePageHeader";
import { useContext } from "react";
import SessionContext from "../../context/SessionContext";

const MainHeader = (props) => {
  const sessionContext = useContext(SessionContext);

  return (
    <BasePageHeader sectionName={"Main Header"} background={Background}>
      <HeaderPanel
        className={`
                    md:w-[35rem] md:right-4 md:bottom-40
                `}
      >
        <h1 className="text-white text-xl md:text-3xl font-bold">
          Sydney Tsunami Dragon Boat Club
        </h1>
        <blockquote>
          <p className="text-md md:text-lg text-white font-normal">
            When fitness mixes with water, it&apos;s got to be SERIOUS FUN! We
            are a dragon boat club in Sydney, New South Wales, Australia.
          </p>
        </blockquote>
        <button
          className="text-white py-2 px-3 rounded-md bg-club-red w-[12rem] md:w-[15rem] font-semibold"
          onClick={() => sessionContext.setShowNewPaddlerForm(true)}
        >
          Try Paddling for FREE
        </button>
      </HeaderPanel>
    </BasePageHeader>
  );
};

export default MainHeader;
