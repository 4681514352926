import ImageIconLink from "../common/ImageIconLink";
import sponsor_pyrphar from "../../assets/images/logopharmX.jpg";
import PageSection from "./PageSection";
import NavigationButton from "../common/NavigationButton";

const sponsors = [
    {
        target: "#",
        label: "Pyrmont Pharmacy",
        img_source: sponsor_pyrphar,
    },
];

const affiliates = [
    {
        target: "https://www.dbnsw.org.au/",
        label: "Dragon Boat NSW",
        img_source: "https://cdn.revolutionise.com.au/logos/wqp9dxgthkskw9sa.png",
    },
    {
        target: "https://www.ausdbf.com.au/",
        label: "Australian Dragon Boat Federation",
        img_source:
            "https://s3-ap-southeast-2.amazonaws.com/piano.revolutionise.com.au/cups/dbnsw/files/gtcjcmu2uz5tkzuk.png",
    },
    {
        target: "https://www.dragonboat.sport/",
        label: "International Dragon Boat FederationW",
        img_source:
            "https://cdn.revolutionise.com.au/sponsors/nv6ffseoswn2oeoa.jpg",
    },
];

const Affiliations = (props) => {
    return (
        <PageSection id={"affiliates"} header={"Affiliations and sponsors"}>
            <div className={`flex justify-center items-center`}>
                {affiliates.map((item) => (
                    <div className={`mx-8 my-4`} key={item.target}>
                        <ImageIconLink
                            target={item.target}
                            label={item.label}
                            img_source={item.img_source}
                        />
                    </div>
                ))}
            </div>
            <div className={`flex justify-center items-center`}>
                {sponsors.map((item) => (
                    <div className={`mx-8 my-4`} key={item.label}>
                        <ImageIconLink
                            target={item.target}
                            label={item.label}
                            img_source={item.img_source}
                        />
                    </div>
                ))}
            </div>
            <div className="flex justify-end pt-4">
                <NavigationButton address={"/sponsorship"} label={"Contact us on sponsorship"}/>
            </div>
        </PageSection>
    );
};

export default Affiliations;
