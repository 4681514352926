const HamburgerIcon = ({flag, className}) => {
    return (

    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={`${!flag ? "M4 6h16M4 12h16M4 18h16" : "M6 18L18 6M6 6l12 12"}`}
        />
    </svg>
    );
};

export default HamburgerIcon;