import React from "react";
import Sponsorship from "../components/sections/Sponsorship";
import Header from "../components/sections/Header";
// import Paddlers from "../components/paddlers/Paddlers";
import Stories from "../components/sections/Stories";
import Page from "./Page";
import TrainingDetails from "../components/sections/TrainingDetails";
import Highlight from "../components/sections/Highlight";

function Home() {
    return (
        <Page
            title={"Sydney Tsunami Dragon Boat Club"}
            description={`
                Sydney Tsunami Dragon Boat Club Inc. is a dragon boat club in Sydney, New South Wales, Australia.  
                We train in Blackwattle Bay which gives us a good view of the Anzac Bridge every training session.
                Dragon boating is a fast, fun and furious man-powered watercraft based paddling sport. It originates 
                from ancient China 2000 years ago where dragon boating or dragon boat racing were held to observe 
                religious and community celebrations.
            `}
        >
            <Header/>
            <Highlight/>
            <Stories/>
            {/*<Paddlers/>*/}
            <TrainingDetails/>
            <Sponsorship/>
        </Page>
    );
}

export default Home;
