import ReactDOM from "react-dom";
import BackDrop from "../common/Backdrop";

const BackDropModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
    </>
  );
};

export default BackDropModal;
