import Markdown from "react-markdown";
import Input from "./Input";
import Dropdown from "./Dropdown";
import Textarea from "./Textarea";
import FormButton from "./FormButton";
import {useState} from "react";
import Modal from "../modals/Modal";
import {sendEmail} from "../../helpers/SendEmail";

const getMessageType = (type) => {
    switch (type) {
        case "sponsorship":
            return "Club sponsorship";
        case "teambuilding":
            return "Organise team building event";
        case "corporate":
            return "Corporate crew coaching/training";
        default:
            return "General enquiry";
    }
};

export default function ContactUsForm({type, processData}) {
    const [markdownHeader,] = useState(defaultMarkdownHeader);
    const [data, setData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [sending, setSending] = useState(false);

    const sourceOptions = [
        "General enquiry",
        "Club sponsorship",
        "Organise team building event",
        "Corporate crew coaching/training"
    ]


    const submitHandler = (event) => {
        event.preventDefault()
        setSending(true)
        sendEmail(data).then(response => {
            if (response) {
                setShowModal(true);
            }
        })
    }

    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData({...data, [name]: value});
    };

    console.log(type)
    console.log(getMessageType(type))

    return (
        <>
            <Modal
                show={showModal}
                message="Thank you for your enquiry. We will be in touch as soon as possible!"
                headline="Message sent!"
                redirectUrl="/"
            />
            <div className={`text-base max-w-lg overflow-auto pb-2`}>
                <form className={`space-y-2 md:px-4`} onSubmit={submitHandler}>
                    <Dropdown value={""} options={sourceOptions}
                              defaultValue={getMessageType(type)}
                              placeholder={"Select category"}
                              name={"messageType"}
                              sending={sending}
                              changeHandler={changeHandler}/>
                    <Markdown className={`font-normal py-4`}>
                        {markdownHeader}
                    </Markdown>
                    <Input name={"fullName"}
                           placeholder="Contact's name"
                           sending={sending}
                           changeHandler={changeHandler}/>
                    <Input name={"email"} type={"email"}
                           placeholder={"Email address"}
                           sending={sending}
                           changeHandler={changeHandler}/>

                    <Textarea sending={sending}
                              rows={4}
                              name={"message"}
                              changeHandler={changeHandler}
                              placeholder={"Type your message here"}/>
                    <FormButton sending={sending} type={"submit"}/>
                </form>
            </div>
        </>
    )
}

const defaultMarkdownHeader = `
Please fill up this form and we will get back to you as soon as we get your message.
`