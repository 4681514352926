import React, {useState,} from "react";
import Story from "../stories/Story";
import BackDropModal from "../modals/BackDropModal";

// import NavigationButton from "../common/NavigationButton";
import PageSectionFull from "./PageSectionFull";
// import SocialMedia from "../widgets/SocialMedia";

const stories = [
    {
        url: "https://www.instagram.com/p/Cxg93ymBXp6/",
        source: "instagram"
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/853895559761412",
        source: "facebook",
    },
    {
        url: "https://www.instagram.com/p/Cxd9m9cvYZC/",
        source: "instagram",
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/849126743571627",
        source: "facebook",
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/847742960376672",
        source: "facebook",
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/854324036385231",
        source: "facebook",
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/849957880155180",
        source: "facebook",
    },
    {
        url: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/posts/848537376963897",
        source: "facebook",
    },
];

const Stories = (props) => {
        const [story, setStory] = useState();
        const [showAllStories, setShowAllStories] = useState(false);

        const clearStoryHandler = () => {
            setStory(null);
        };

        const openStoryHandler = (url) => {
            window.open(url);
        };

        return (
            <PageSectionFull id={"stories"} header={"Featured stories"} className={``}>
                {story && <BackDropModal onClose={clearStoryHandler}/>}
                {stories.length > 0 && (
                    <section className={`flex justify-center max-w-6xl`}>
                        <div className={`
                                grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2
                                ${!showAllStories ? "max-h-[36rem] overflow-hidden" : "mb-30"}
                            `}>
                            {[...Array((3)).keys()].map(i => (
                                <ul key={i} className={`space-y-2`}>
                                    {stories.slice(i * 2, ((i * 2) + (stories.length / 3))).map(({url, source}, index) => (
                                        <li key={index}>
                                            <Story
                                                url={url}
                                                source={source}
                                                openStory={openStoryHandler}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                        <div className="
                                inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-black pt-32 pb-8
                                pointer-events-none dark:from-slate-900 absolute
                                gap-2
                                z-10">
                            <button
                                onClick={() => {
                                    setShowAllStories(!showAllStories);
                                }}
                                type="button"
                                className={`
                                        relative bg-red-900 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 
                                        text-sm text-white font-semibold h-12 px-6 rounded-lg flex items-center dark:bg-red-700 dark:hover:bg-red-600 
                                        pointer-events-auto
                                    `}>
                                {showAllStories ? "Minimize stories ..." : "Show all stories ..."}
                            </button>
                            {/*<div className={`bg-red-900 p-2 cursor-pointer`}>*/}
                            {/*    <SocialMedia socials={["facebook", "instagram"]} classname={`text-white text-[0.65rem] flex items-center`}/>*/}
                            {/*</div>*/}

                            {/*<NavigationButton address={"https://www.facebook.com/SydneyTsunamiDragonBoatClub"}*/}
                            {/*                  label={"Visit our Facebook Page"}/>*/}
                            {/*<NavigationButton address={"https://www.instagram.com/sydneytsunami/"}*/}
                            {/*                  label={"Visit our Instagram Page"}/>*/}
                        </div>
                    </section>
                )}

            </PageSectionFull>
        )
            ;
    }
;

export default Stories;
