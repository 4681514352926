import {createContext, useState} from "react";

const SessionContext = createContext({
    showPaddlerForm: false,
    showContactUsForm: false
})

export function SessionContextProvider({children}) {
    const [showNewPaddlerForm, setShowNewPaddlerForm] = useState(false)
    const [showContactUsForm, setShowContactUsForm] = useState(false)

    return (
        <SessionContext.Provider value={{
            showNewPaddlerForm,
            setShowNewPaddlerForm,
            showContactUsForm,
            setShowContactUsForm
        }}>
            {children}
        </SessionContext.Provider>
    )

}

export default SessionContext;