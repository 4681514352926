import Markdown from "react-markdown";
import Input from "./Input";
import Dropdown from "./Dropdown";
import Textarea from "./Textarea";
import DateInput from "./DateInput";
import FormButton from "./FormButton";
import {useState} from "react";
import Modal from "../modals/Modal";
import {processData as processNewPaddlerData} from "../../helpers/ProcessNewPaddler";
// import {redirect} from "react-router-dom";

export default function NewPaddlerForm() {
    const [markdownInfo,] = useState(defaultMarkdownInfo);
    const [markdownHeader,] = useState(defaultMarkdownHeader);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});

    const sourceOptions = [
        "Friend", "Family", "Corporate training", "Search engine",
        "Local newspaper or media", "Was handed a flyer", "Other"
    ]

    const [sending, setSending] = useState(false);

    const submitHandler = (event) => {
        event.preventDefault()
        setSending(true)
        // processData(data)
        processNewPaddlerData(data).then(response => {
            if (response) {
                setShowModal(true);
            }
        })
    }

    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData({...data, [name]: value});
    };

    const handleModal = () => {
        window.location.reload()
    }

    return (
        <>
            <Modal
                show={showModal}
                message="Thank you for your interest to paddle with us. We will be in touch as soon as possible!"
                headline="New paddler form sent!"
                redirectUrl="/"
                closeModal={handleModal}
            />

            <div className={`text-base w-full md:max-w-xl 
                max-h-[28rem] sm:max-h-[40rem]
                md:max-h-[40rem] overflow-auto
            `}>
                <form className={`space-y-4 md:px-4`} onSubmit={submitHandler}>
                    <Markdown className={`py-2`}>
                        {markdownHeader}
                    </Markdown>
                    <Input value={data?.fullName} name={"fullName"}
                           placeholder={"Full Name"}
                           sending={sending}
                           changeHandler={changeHandler}/>
                    <div className={`flex gap-4`}>
                        <Input value={data?.email} name={"email"} type={"email"}
                               placeholder={"Email address"}
                               sending={sending}
                               changeHandler={changeHandler}/>
                        <Input value={data?.mobile} name={"mobile"} type={"tel"}
                               placeholder={"Mobile number"}
                               sending={sending}
                               changeHandler={changeHandler}/>
                    </div>
                    <Dropdown value={data?.source} options={sourceOptions}
                              placeholder={"Where did you hear about us?"}
                              name={"source"}
                              sending={sending}
                              changeHandler={changeHandler}/>
                    <Textarea sending={sending}
                              rows={2}
                              value={data?.comments}
                              name={"comments"}
                              changeHandler={changeHandler}
                              placeholder={"Any comments you wish to let us know?"}/>
                    <Markdown className={`bg-slate-200 p-4 shadow-md space-y-8`}>
                        {markdownInfo}
                    </Markdown>
                    <DateInput type={"date"} name={"firstSession"} value={data?.firstSession}
                               sending={sending} changeHandler={changeHandler}
                               label={"I plan to attend my first paddle session on:"}/>
                    <FormButton sending={sending} type={"submit"}/>
                </form>
            </div>
        </>
    )
}

const defaultMarkdownHeader = `
Are you interested in trying dragon boat paddling with us? Then this is the form to complete!
We welcome new paddlers throughout the season.
`

const defaultMarkdownInfo = `
**WATER SAFETY**: If you are unable to swim 50m or are under
18 years of age, you are required to wear a PFD (life jacket)
whilst dragon boating. We will provide this when you are at
training but you are free to bring your own if you have one.

**TRAINING INFO**: We currently train Tuesday and Thursday nights and
Saturday mornings. Please check our website calendar for our training days and
start times as occasionally they do change. Use this as a guide
to planning your first paddling sesion with us.
`