import {useSearchParams} from 'react-router-dom';

import {useContext,} from "react";
import SessionContextProvider from "../../context/SessionContext";
import ContactUsForm from "../forms/ContactUsForm";

export default function ContactUsWidget() {
    const sessionContext = useContext(SessionContextProvider)
    const [searchParams] = useSearchParams();

    const type = searchParams.get("type")

    return (
        <div className={`
                absolute top-44 left-0 bg-white/95 p-4 md:rounded-r-lg w-full md:w-[27rem]
                border border-club-red shadow-md
                ${sessionContext.showContactUsForm && !sessionContext.showNewPaddlerForm ? "block" : "hidden"}
                animate-[moveRight_1s_ease-in-out]
            `}>
            <div className={`relative pt-8`}>
                <div className={`
                            absolute -top-8 md:top-24 right-0 md:-right-20 
                            bg-club-red text-md
                            text-white font-semibold p-2 px-4
                            md:rotate-90
                        `}>
                    Contact us
                </div>
                {/*<div className={`*/}
                {/*        absolute -top-4 -right-4 bg-gray-400 text-white font-semibold */}
                {/*        p-2 px-4 cursor-pointer`}*/}
                {/*     onClick={() => sessionContext.setShowContactUsForm(false)}>*/}
                {/*    Close*/}
                {/*</div>*/}
                <ContactUsForm type={type}/>
            </div>
        </div>
    )
}