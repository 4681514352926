import Background from "../../assets/images/corporate.jpg";
import HeaderPanel from "../common/HeaderPanel";
import BasePageHeader from "../common/BasePageHeader";
import {Link} from "react-router-dom";

const CorporateHeader = (props) => {
    return (
        <BasePageHeader background={Background} sectionName={"Corporate Header"}>
            <HeaderPanel className={` md:top-40 md:left-12 h-fit md:max-w-lg`}>
                <blockquote>
                    <p className=" text-white font-normal ">
                        If you are a corporate crew and you need help with training and
                        coaching, we can surely help!
                    </p>
                </blockquote>
                <Link to="/contactus?type=corporate">
                    <button className=" text-white py-2 px-2 rounded-md bg-club-red w-60 ">
                        Contact us for more details
                    </button>
                </Link>
            </HeaderPanel>
        </BasePageHeader>
    );
};

export default CorporateHeader;
