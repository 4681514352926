import React from "react";

const IndexCard = ({header, content}) => {
    return (
        <div className="font-medium">
            <div className={`rounded-t-md text-sm text-white bg-club-red p-2`}>
                {header}
            </div>
            <div className={`rounded-b-md text-sm md:text-md text-club-red p-2 border border-club-red`}>
                {content}
            </div>
        </div>
    )
}

export default IndexCard;