import PageSection from "./PageSection";
import React from "react";

export default function Highlight() {
    return (
        <PageSection id="highlight" header={""}
            className={`bg-club-red pb-12`}>

            <div className="w-full flex flex-col md:justify-center items-center gap-4 text-white px-4">
                <h1 className={`text-2xl font-bold`}>Sydney Tsunami Dragon Boat Club</h1>
                <p>
                    <b>Sydney Tsunami Dragon Boat Club Inc.</b> is a dragon boat club in
                    <b>Sydney, New South Wales, Australia</b>.
                    We train in <b>Blackwattle Bay</b> which gives us a good view of
                    the <b>Anzac Bridge</b> every training session. <b>Dragon boating</b> is
                    a fast, fun and furious man-powered watercraft based paddling sport. It originates
                    from ancient China 2000 years ago where dragon boating or dragon boat racing were held to observe
                    religious and community celebrations.
                </p>
            </div>

        </PageSection>
    )
}