import Page from "./Page";

function Admin() {
  return (
    <Page>
      <h1>Admin section</h1>
    </Page>
  );
}

export default Admin;
