import {Link} from "react-router-dom";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const NavigationButton = ({address, label}) => {
    return (
        <Link to={address} target={"_blank"}>
            <div className="cursor-pointer flex shadow-md">
                <div className="bg-white rounded-l-md text-xs text-club-red border border-club-red p-2 font-semibold">
                    {label}
                </div>
                {/*<FontAwesomeIcon*/}
                {/*    className="rounded-r-md w-8 bg-club-red text-white p-2"*/}
                {/*    // icon={faArrowRight}*/}
                {/*/>*/}
            </div>
        </Link>
    );
};

export default NavigationButton;