import {BrowserRouter, Routes, Route, Outlet} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import MenuBar from "./components/common/MenuBar";

import "./App.css";
import styles from "./Main.module.css";
import Admin from "./pages/Admin";
import Faq from "./pages/Faq";
import NewPaddler from "./pages/NewPaddler";
import ContactUs from "./pages/ContactUs";
import {SessionContextProvider} from "./context/SessionContext";

function App() {
    return (
        <div className={styles.main}>
            <BrowserRouter>
                <SessionContextProvider>
                    <MenuBar/>
                    <Routes>
                        <Route path="/" element={<Outlet/>}>
                            <Route index element={<Home/>}/>
                            <Route path="about" element={<About/>}/>
                            <Route path="admin" element={<Admin/>}/>
                            <Route path="faq" element={<Faq/>}/>
                            <Route path="newpaddler" element={<NewPaddler/>}/>
                            <Route path="contactus" element={<ContactUs/>}/>
                        </Route>
                    </Routes>
                </SessionContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
