import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

export default function SocialMedia({socials, classname}) {
    return (
        <div className={`flex gap-4 ${classname}`}>
            <Link to={socials.facebook} target={"_blank"}>
                <FontAwesomeIcon icon={faFacebook} size={`3x`}/>
            </Link>
            <Link to={socials.instagram} target={"_blank"}>
                <FontAwesomeIcon icon={faInstagram} size={`3x`}/>
            </Link>
        </div>
    )
}