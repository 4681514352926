import Background from "../../assets/images/teambuilding.jpg";
import BasePageHeader from "../common/BasePageHeader";
import HeaderPanel from "../common/HeaderPanel";
import {Link} from "react-router-dom";

const TeamBuildingHeader = (props) => {
    return (
        <BasePageHeader background={Background} sectionName={"Team Building Header"}
                        backgroundClassName={`w-[100vw] object-center`}>
            <HeaderPanel className={`
                    absolute md:top-40 md:left-12 h-fit md:max-w-lg
                `}>
                <blockquote>
                    <p className=" text-white font-normal ">
                        Are you thinking of having a team building activity? Contact us and
                        we can help you organise!
                    </p>
                </blockquote>

                <Link to="/contactus?type=teambuilding">
                    <button
                        className=" text-white py-2 px-2 rounded-md bg-club-red w-60 ">
                        Contact us for more details
                    </button>
                </Link>
            </HeaderPanel>
        </BasePageHeader>
    );
};

export default TeamBuildingHeader;
