const PageSection = ({id, header, children, className, headerColor}) => {
    return (
        <section id={id} className={`
                w-full flex justify-center p-4 mb-8 
                ${className}
            `}>
            <div className="flex flex-col gap-2 w-full max-w-4xl">
                <h1 className={`
                    text-lg font-bold py-2
                    ${headerColor}
                `}>{header}</h1>
                {children}
            </div>
        </section>
    )
}

export default PageSection;