import Background from "../../assets/images/training.jpg";
import HeaderPanel from "../common/HeaderPanel";
import BasePageHeader from "../common/BasePageHeader";
import {Link} from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";

const TrainingHeader = (props) => {
    return (
        <BasePageHeader sectionName={"Training Header"} background={Background}
            backgroundClassName={`w-[100vw] object-center`}>
            <HeaderPanel className={`
                    md:w-[35rem] md:right-24 md:bottom-40
                `}>

                <h1 className="text-lg text-white font-semibold">
                    We train to stay fit and healthy!
                </h1>
                <blockquote>
                    <p className="text-md text-white font-normal">
                        We paddle throughout the year, so it&apos;s always a good time to
                        start. Best of all, the first three lessons are absolutely FREE!
                    </p>
                </blockquote>

                <Link
                    // activeClass="Home"
                    to="training"
                    smooth={true}
                    offset={-100}
                    duration={500}
                >
                    <div className="cursor-pointer flex items-center shadow-md ">
                        <div className="bg-white rounded-l-md text-sm text-club-red border border-club-red p-2 font-semibold">
                            Checkout our training schedule
                        </div>
                        <FontAwesomeIcon
                            className="rounded-r-md w-8 bg-club-red text-white p-2 border border-club-red"
                            icon={faArrowDown}
                        />
                    </div>
                </Link>
            </HeaderPanel>
        </BasePageHeader>
    );
};

export default TrainingHeader;
