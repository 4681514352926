import Page from "./Page";
import Background from "../assets/images/frontrow.jpg";
import BasePageHeader from "../components/common/BasePageHeader";
import HeaderPanel from "../components/common/HeaderPanel";
import NewPaddlerForm from "../components/forms/NewPaddlerForm";

function NewPaddler() {
    return (
        <Page
            title={"New paddler"}
            description={`
                Sydney Tsunami Dragon Boat Club Inc. is a dragon boat club in Sydney, New South Wales, Australia. 
                Dragon boating is one of Australia's fastest growing sports, and we are always keen to have new 
                paddlers try us out.
            `}>
            <BasePageHeader sectionName={"New paddler"} background={Background}
                            backgroundClassName={`brightness-150 md:brightness-100 md:object-left-top`}>
                <HeaderPanel className={`
                    md:left-12 top-32 max-h-screen md:max-h-[90%] w-full md:max-w-[40rem]
                    bg-white/95 md:bg-white/95 items-center
                `}>
                    <NewPaddlerForm/>
                </HeaderPanel>
            </BasePageHeader>
        </Page>
    );
}

export default NewPaddler;


