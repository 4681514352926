import emailjs from "@emailjs/browser";

export const sendEmail = async (data, templateId) => {
    try {
        const emailTemplate =
            templateId || process.env.REACT_APP_EMAILJS_CONTACTUS_TEMPLATE_ID;
        const response = await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
            emailTemplate || "",
            {
                ...data,
                toEmail: data.toEmail || "info@sydneytsunami.org.au",
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );

        if (response) {
            console.log("SUCCESS!", response.status, response.text);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};
