import React, {useContext, useEffect,} from "react";
import Page from "./Page";
import Background from "../assets/images/citiyview.jpg";
import BasePageHeader from "../components/common/BasePageHeader";
import SessionContext from "../context/SessionContext";
import ContactUsWidget from "../components/widgets/ContactUsWidget";

export default function ContactUs() {
    const sessionContext = useContext(SessionContext)

    useEffect(() => {
        sessionContext.setShowContactUsForm(true)
    }, [sessionContext]);

    return (
        <Page
            title={"Contact us"}
            description={`
                Sydney Tsunami Dragon Boat Club Inc. is a dragon boat club in Sydney, New South Wales, 
                Australia. Dragon boating is one of Australia's fastest growing sports, and we are always 
                keen to have new paddlers try us out.
            `}
        >
            <BasePageHeader sectionName={"New paddler"} background={Background}
                            backgroundClassName={`brightness-150 md:brightness-100 md:object-bottom-right`}>
                <ContactUsWidget/>
                {/*<HeaderPanel className={`*/}
                {/*    md:left-12 top-56 max-h-screen md:max-h-fit w-full md:max-w-[30rem]*/}
                {/*    bg-white/95 md:bg-white/95 items-center*/}
                {/*`}>*/}
                {/*    /!*<ContactUsForm processData={processData}/>*!/*/}
                {/*</HeaderPanel>*/}
            </BasePageHeader>
        </Page>
    );
}



