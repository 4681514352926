import {sendEmail} from "./SendEmail";

const enrichData = (data) => {
    const firstname = data.fullName.split(" ")[0];

    const trainingWeekdays = ["Tuesdays", "Thursdays"];
    // if (getEnvVar("REACT_APP_TRAINING_TUESDAY") === "true") {
    //   trainingWeekdays.push("Tuesdays");
    // }

    // if (getEnvVar("REACT_APP_TRAINING_THURSDAY") === "true") {
    //   trainingWeekdays.push("Thursdays");
    // }

    const trainingMessage = [];
    if (trainingWeekdays.length > 0) {
        trainingMessage.push(
            `On ${trainingWeekdays.join(
                " and "
            )} we warm up at 6.30pm and are off the water at 8pm.`
        );
    }

    // const trainingWeekends = true
    // if (getEnvVar("REACT_APP_TRAINING_SATURDAY") === "true") {
    trainingMessage.push(
        `On Saturday mornings we meet at 8.30am and training concludes at 10.30am. After our Saturday session, some of the crew head to a local cafe for a well earned breakfast.`
    );

    return {
        ...data,
        subject: "Dragon Boating with Sydney Tsunami",
        fromEmail: "recruitment@sydneytsunami.org.au",
        replyTo: "recruitment@sydneytsunami.org.au",
        ccEmail: "recruitment@sydneytsunami.org.au",
        fullName: firstname,
        trainingMessage: trainingMessage.join(" "),
    }
}
export const processData = async (data, templateId) => {
    try {
        const enrichedData = enrichData(data);

        const paddlerEmailSent = await sendEmail(
            {
                ...enrichedData,
                toEmail: enrichedData.email
            },
            process.env.REACT_APP_EMAILJS_NEW_PADDLER_TEMPLATE)

        if (paddlerEmailSent) {
            const internalEmailSent = await sendEmail(
                {
                    ...data,
                    messageType: "New paddler enquiry",
                    message: `## New paddler enquiry /
                            Contact Number: ${data.mobile} / 
                            Source: ${data.source} / 
                            Comments: ${data.message} / 
                            I plan to do my first session on ${data.firstSession}`,
                    toEmail: "recruitment@sydneytsunami.org.au",
                },
                ""
            )
            return (internalEmailSent)
        }
    } catch (error) {
        return false;
    }
};
