import React from "react";

export default function Textarea({name, placeholder, sending, changeHandler, rows}) {
    return (
        <div className="w-full flex items-center border-b border-red-200 py-2 pt-4">
              <textarea
                  className="form-textarea mt-1 block w-full px-2 border-none outline-none"
                  rows={rows || 3}
                  placeholder={placeholder}
                  name={name}
                  onChange={changeHandler}
                  disabled={sending}
              ></textarea>
        </div>
    )
}