import {useEffect} from "react";
import Footer from "../components/footer/Footer";
import {Helmet} from "react-helmet";

const Page = ({title, description, children, className}) => {
    useEffect(() => {
        const head = document.querySelector('head');
        const gtlink = "https://www.googletagmanager.com/gtag/js?id=G-CCB9C03BDG"
        if (
            head.firstElementChild?.src !== "https://www.google-analytics.com/analytics.js" &&
            head.firstElementChild?.src !== gtlink
        ) {
            const script = document.createElement("script")
            script.appendChild(document.createTextNode(`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-CCB9C03BDG');
            `))
            head.insertBefore(script, head.firstChild);

            const mainscript = document.createElement("script")
            mainscript.async = true
            mainscript.src = gtlink
            head.insertBefore(mainscript, head.firstChild);
        }

        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                {/*<meta name="keywords" content="dragon boat,dragonboat,club,sydney tsunami,paddling,sport,fitness,free"/>*/}
                <meta property="og:title" content={title}/>
                {/*<meta property="og:image" content="path/to/image.jpg" />*/}
            </Helmet>
            <section className={`
                w-full absolute top-0 -z-1 flex flex-col
                ${className}
            `}>
                {children}
                <Footer/>
            </section>
        </div>
    );
};

export default Page;
