const ImageIconLink = ({target, label, img_source, svg_source, imgClassName}) => {
    return (
        <div className={`px-1 py-2`}>
            <a
                href={target}
                aria-label={label}
                target="_blank"
                rel="noreferrer"
            >
                {img_source && (
                    <img
                        className={`
                            w-[7rem] hover:scale-125 transition-all duration-300
                            ${imgClassName}
                        `}
                        src={img_source}
                        alt={label}
                    />
                )}
                {svg_source && (
                    <svg className={`
                            w-4 h-4 fill-white px-0.5 py-1
                            ${imgClassName}
                        `}>
                        <use href={svg_source}></use>
                    </svg>
                )}
            </a>
        </div>
    );
};

export default ImageIconLink;
