import React from "react";

export default function Input({value, type, name, sending, placeholder, changeHandler}) {
    return (
        <div
            className={`w-full flex items-center border-b py-1
                ${value ? "border-black-100" : "border-red-400"}
              `}
        >
            <input className={`
                    appearance-none bg-transparent border-none w-full
                    text-gray-700 mr-3 py-1 
                    px-2 leading-tight focus:outline-none
                    `}
                   type={type ?? "text"}
                   placeholder={placeholder}
                   name={name}
                   aria-label={placeholder}
                   onChange={changeHandler}
                   disabled={sending}
                   required
            />
        </div>
    )
}
