import {useState} from "react";
import Page from "./Page";
import Markdown from 'react-markdown'
import Background from "../assets/images/topviewtwoboats.jpg";
import BasePageHeader from "../components/common/BasePageHeader";
import HeaderPanel from "../components/common/HeaderPanel";
// import {Link} from "react-router-dom";

function About() {
    const [markdownSource,] = useState(defaultMarkdown);

    return (
        <Page
            title={`Frequently asked questions`}
            description={`
                To equip new paddlers with information about paddling, here is a
                new paddler survival kit and frequently asked questions on dragon boat
                and the club.
            `}
        >
            <BasePageHeader sectionName={"FAQ"} background={Background}
                backgroundClassName={`brightness-150 md:brightness-100 md:object-left-top`}>
                <HeaderPanel className={`
                    md:left-4 top-36 md:max-h-[95%] md:max-w-[40rem]
                    bg-stone-900/80 md:bg-stone-900/80
                `}>
                    <h1 className={`text-white font-bold text-2xl`}>Frequently asked questions</h1>
                    <Markdown className={`text-white overflow-auto space-y-4`}
                              components={{
                                  h3(props) {
                                      const {node, ...rest} = props
                                      // eslint-disable-next-line jsx-a11y/heading-has-content
                                      return <h3 className={`font-bold`} {...rest}/>
                                  }
                              }}>
                        {markdownSource}
                    </Markdown>
                </HeaderPanel>
            </BasePageHeader>
        </Page>)
        ;
}

export default About;

const defaultMarkdown = `
### Wow - dragon boating looks really hard. Do I need to be super fit?
Not at all! You will need to have a reasonable level of fitness to compete in a race, but stamina is 
something you build up over time when you train with the crew. It doesn't matter if you are not really very 
fit when you start out because we'll work on that together in a friendly, highly-supportive environment.

### How often do you race?
Even though we train throughout the year, the dragon boat season runs from August to April. During this time, 
there will generally be one regatta a month in various locations in Sydney and away (eg. Central Coast and Canberra). 
The most high profile is the Lunar New Year regatta in Darling Harbour in February and the season culminates in the 
State Titles at the Sydney International Regatta Centre in Penrith in April.

Regattas are the team's chance to put into practice what we've learned in training and pit ourselves against the 
best of the best in NSW. Races are mostly either 250m or 500m. They're fast and furious - but a whole lot of fun, too!

### What if I can't attend all the training sessions?
We completely understand that lives can be very busy with work, relationships, friends and family. If you can't 
make it to training every session, that's OK - just make it as often as you can. Of course, the more you come, 
the more you'll get out of it and the better paddler you will be, so we'll encourage you to come as often as 
possible.

If you need to take a short hiatus from training due to travel, illness or injury, let us know and 
we'll help you get back into the swing of things when you return.


### What can I expect at a training session?
Training starts out with on on-land warm-up where we stretch our muscles and get our hearts pumping with some fast 
and fun exercises for about 10 mins. We'll line up in our boat positions before lifting the boat/s off their racks 
and into the water.

Safety considerations are paramount so we'll take you through the safety procedures and
'buddy system' before we go out. If you are concerned for any reason, we can supply you with a life jacket.

We load into the boat back-to-front (that is, with the back rows boarding first). We'll do a 5min warm up on the water - 
some light paddling - before the training session starts. The coaches prepare different drills every session so it's 
never boring. Drills may have an emphasis on technique, power or endurance, depending on what we need work on. 
We might do some race starts and race practices in preparation for an upcoming regatta. We'll round off the water 
session with a warm down before getting the boat out of the water and doing our on-land warm down stretches.

An average dragon boat session goes for about 2 hours including on-land time. Don't worry if you can't paddle the 
whole time we're on the water - sometimes if your first time is before a race day, of course you won't be able to 
paddle like a regular. You'll build up your stamina over the first month or two.

### If there are two paddlers for every row, don't you get really big on one side?
No, we make sure that after every set, we swap sides (you or your partner stands up and you swap seats) before doing 
the set again on the other side. It is important that we are ambidextrous since we will perform better if we don't 
tire out one side.

### How much does it cost?
Sydney Tsunami Dragon Boat Club is a not-for-profit organisation which has a strong fund-raising ethos meaning 
that the cost to our members remains very modest.  Speak to us at training to enquire about 'membership fees'. 
When you’d like to race, you’ll need to buy a race rashie and most people will eventually buy their own paddle 
(we have paddles to borrow in the meantime).

### What is the culture of the club like?
True to our motto of 'Serious Fun', Sydney Tsunami are a notoriously friendly bunch, who are only fierce on the race 
course. We pride ourselves on being fun-loving, good natured and most importantly, 'good sports'. We like to win, 
but not win at all costs. We are also proud of our history of volunteering within the greater dragon boating community, 
contributing to our DBNSW Club of the Year Award in 2005/2006 and again in 2011.

We are serious about our 
paddling and place great emphasis on perfecting our technique so we can paddle together as efficiently as possible 
as a team. There is always something to learn, and  if you are already a fit and competitive in nature,  you still 
will be challenged by our training regime. We also run land training sessions and on-going 'Fitness Quests' where you 
can challenge yourself to improve your fitness from whatever your start-point is.Serious Fun means we are not 
just a social club but a club that trains to race. As a club we support our members to achieve their very best. 
We offer the highest level of support and encouragement for those looking to race competitively, be that at club, 
State or a National level. Over the past few years this has resulted in Tsunami having one of the highest number of 
club members representing NSW and Australia. Recently 8 Tsunami members raced in Hungary on the Australian National 
Team, and 27 members raced in the NSW State Team.We are super-supportive of our 'newbies' and definitely won't bite. 
But don't just take our word for it - come down and meet us yourself.

### But what if it rains?
Dragon boaters are made of tough stuff, and yes, we even go out in the rain! In fact, it can be quite pleasant as the 
water is often calm. You're bound to get wet, anyway, you see. :) But seriously, if it's raining outside, you should 
turn up anyway where we'll make an assessment prior to getting on the water. Often the weather will blow over by 
the time training starts, but you just don't know until you get there. We will never go out in dubious weather 
conditions (white caps or lightning being the main indicators) which may pose a safety risk to the team. And if it 
does get cancelled, we'll head to breakfast or dinner at a pub instead!

### What should I wear to paddle?
Wear a t-shirt and board shorts or gym attire that you don’t mind getting wet in, and a pair of closed toed 
shoes – these could be old trainers, converse or a pair of crocs – toes need to be covered. Further, bring a plastic 
water bottle and make sure you’re wearing sunscreen for sunny Saturday sessions, and a cap or visor.
`