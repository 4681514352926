import LazyLoad from "../utils/LazyLoad";

const BasePageHeader = ({background, sectionName, children, backgroundClassName}) => {
    return (
        <div className={`h-[100vh] overflow-hidden bg-slate-300 relative animate-fadeInAndOut`}>
            <LazyLoad src={background} alt={sectionName} className={backgroundClassName}/>
            {children}
        </div>
    )
}

export default BasePageHeader;