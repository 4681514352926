import NewPaddlerForm from "../forms/NewPaddlerForm";
import {useContext, useEffect, useRef, } from "react";
import SessionContextProvider from "../../context/SessionContext";

export default function TryPaddling() {
    const ref = useRef()

    const sessionContext = useContext(SessionContextProvider)

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                sessionContext.setShowNewPaddlerForm(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <>
            <div className={`
                fixed top-24 right-0
                ${sessionContext.showNewPaddlerForm ? "hidden" : "block"}
            `}>
                <button
                    onClick={() => sessionContext.setShowNewPaddlerForm(true)}
                    className={`
                    text-white py-2 px-2
                    bg-club-red text-md font-semibold
                    rounded-l-lg shadow-md
                `}>
                    Try Paddling for FREE
                </button>
            </div>
            <div ref={ref} className={`
                absolute top-32 right-0 bg-white p-4 rounded-l-lg w-full md:w-[30rem]
                border border-club-red shadow-md
                ${sessionContext.showNewPaddlerForm ? "block" : "hidden"}
                animate-[moveLeft_1s_ease-in-out]
            `}>
                {/*animate-in slide-in-from-right*/}
                <div className={`relative pt-8`}>
                    <div className={`absolute -top-9 -left-6 bg-club-red text-white font-semibold p-2 px-4`}>
                        Try paddling for FREE
                    </div>
                    <div className={`absolute -top-4 -right-4 bg-gray-400 text-white font-semibold p-2 px-4 cursor-pointer`}
                         onClick={() => sessionContext.setShowNewPaddlerForm(false)}>
                        Close
                    </div>
                    <NewPaddlerForm/>
                </div>
            </div>
        </>
    )
}