const PageSectionFull = ({id, header, children, className}) => {
    return (
        <section id={id} className={`
                relative w-full flex justify-center p-4 
                ${className}
            `}>
            <div className="flex flex-col gap-2 ">
                <h1 className="text-lg font-bold py-2 max-w-6xl">{header}</h1>
                {children}
            </div>
        </section>
    )
}

export default PageSectionFull;