import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import IndexCard from "../common/IndexCard";
import PageSection from "./PageSection";

const TrainingDetails = () => {
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        setSchedules([
            {
                day: "Tuesday",
                time: "6:30PM-8:00PM",
            },
            {
                day: "Thursday",
                time: "6:30PM-8:00PM",
            },
            {
                day: "Saturday",
                time: "8:30AM-10:30AM",
            },
        ]);

        return () => {
        };
    }, []);

    return (
        <PageSection id="training" header={"Training schedule and location"}>

            <div className="w-full flex flex-col md:flex-row md:justify-center items-center">
                <div className="flex flex-row md:flex-col gap-2 p-2">
                    {schedules.map((s, index) => <IndexCard key={s.day} header={s.day} content={s.time}/>)}
                </div>

                <div className="flex flex-col gap-2">
                    <div className="rounded-md text-xs p-2 border font-semibold">
                        Address: 2 Bank St Pyrmont NSW 2009 (Under the ANZAC Bridge)
                    </div>
                    <iframe
                        className="rounded-md w-full"
                        title="Sydney Tsunami Dragon Boat Club Training Location"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3312.746108793836!2d151.1870005!3d-33.8704335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae3346aaa4a7%3A0x1903075ee887da52!2sSydney%20Tsunami%20Dragon%20Boat%20Club%20Inc!5e0!3m2!1sen!2sau!4v1588457788338!5m2!1sen!2sau"
                        width={450}
                        height={250}
                        style={{border: 0}}
                        aria-hidden="false"
                        loading="lazy"
                    ></iframe>
                </div>
            </div>

            <div className="flex justify-end pt-4">
                <Link to="/newpaddler">
                    <div className="cursor-pointer flex items-center shadow-md">
                        <div className="rounded-l-md text-xs text-club-red border border-club-red p-2 font-semibold">
                            Let us know you&apos;re coming
                        </div>
                        <FontAwesomeIcon
                            className="rounded-r-md w-8 bg-club-red text-white p-2 border border-club-red"
                            icon={faArrowRight}
                        />
                    </div>
                </Link>
            </div>
        </PageSection>
    );
};

export default TrainingDetails;
