import {Link} from "react-router-dom";
import SocialMedia from "../widgets/SocialMedia";

export default function Footer() {
    const socials = {
        facebook: "https://www.facebook.com/SydneyTsunamiDragonBoatClub/",
        instagram: "https://www.instagram.com/sydneytsunami/"
    };

    const affiliations = [
        {
            "shortname": "DBNSW",
            "url": "https://www.dbnsw.org.au/",
            "label": "Dragon Boat NSW",
            "image": "https://cdn.revolutionise.com.au/logos/wqp9dxgthkskw9sa.png"
        },
        {
            "shortname": "AusDBF",
            "url": "https://www.ausdbf.com.au/",
            "label": "Australian Dragon Boat Federation",
            "image": "https://s3-ap-southeast-2.amazonaws.com/piano.revolutionise.com.au/cups/dbnsw/files/gtcjcmu2uz5tkzuk.png"
        },
        {
            "shortname": "IDBF",
            "url": "https://www.dragonboat.sport/",
            "label": "International Dragon Boat FederationW",
            "image": "https://cdn.revolutionise.com.au/sponsors/nv6ffseoswn2oeoa.jpg"
        }
    ];

    return (
        <footer className="w-full">
            <div
                className="relative bg-club-red w-full flex flex-col md:flex-row py-8 px-6 gap-10 text-white">
                <div className="flex flex-col text-sm gap-2">
                    <h1 className="text-lg font-bold">The Club</h1>
                    <Link to="/">Home</Link>
                    <Link to="/about">About us</Link>
                    <Link to="/contactus?type=general">Contact us</Link>
                </div>
                <div className="flex flex-col text-white text-sm gap-2">
                    <h1 className="text-lg font-bold">For Paddlers</h1>
                    <Link to="/paddlers">Featured paddlers</Link>
                    <Link to="/faq">Frequently asked questions</Link>
                    <Link to="/newpaddler">Try us for FREE</Link>
                </div>
                <div className="flex flex-col text-white text-sm gap-2">
                    <h1 className="text-lg font-bold">Affiliations</h1>
                    {affiliations.map(({shortname, url, label}) => (
                        <a
                            key={shortname}
                            href={url}
                            aria-label={label}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p className="text-sm">{shortname}</p>
                        </a>
                    ))}
                </div>
                <div className="absolute right-8 top-8 flex flex-col text-white text-sm gap-2 justify-self-end">
                    <h1 className="text-md font-bold">Follow us on social media</h1>
                    <SocialMedia socials={socials}/>

                </div>
            </div>
            <div className=" w-full bg-black flex items-center justify-between">
                <p className="text-white text-xs p-2">
                    Copyright 2022 - Sydney Tsunami Dragon Boat Club
                </p>
                <p className="text-white text-xs p-2">
                    Designed by <a href="mailto:pablosspot@gmail.com?subject=Website%20Design%20Request">pablosspot.</a></p>
            </div>
        </footer>
    );
};

