const HeaderPanel = ({className, children}) => {
    return (
        <div className={`
                md:rounded-xl absolute flex flex-col
                bg-stone-900/80 md:bg-stone-900/70 p-8 text-left space-y-4
                text-md md:text-lg 
                bottom-24
                ${className}
            `}>
            {children}
        </div>
    );
}

export default HeaderPanel;